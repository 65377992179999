export const callbackArray = [
  {
    callback: "ig",
    url: "https://ig.logicpuzzle.app/config/user/linkage",
  },
  {
    callback: "ig-dev",
    url: "https://ig-dev.logicpuzzle.app/config/user/linkage",
  },
  {
    callback: "abyss",
    url: "https://abyss.logicpuzzle.app/login",
  },
  {
    callback: "abyss-dev",
    url: "http://abyss-dev-bnxhh.logicpuzzle.app/login",
  },
  {
    callback: "puzzle-square",
    url: "https://puzsq.logicpuzzle.app/login",
    buttonName: "パズルスクエアに移動",
  },
  {
    callback: "puzzle-square-dev-vtplm",
    url: "https://puzsq-dev-lhbvc.logicpuzzle.app/login",
    buttonName: "パズルスクエアに移動",
  },
  {
    callback: "pentool",
    url: "https://pentool.logicpuzzle.app/login",
    buttonName: "オンラインペンツールに移動",
  },
  {
    callback: "daily-contest-dev-baakc",
    url: "https://daily-dev-6q4dw.logicpuzzle.app/login",
    buttonName: "デイリーコンテストに移動",
  },
];
