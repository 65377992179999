export type CommonResultMessage = {
  result: boolean;
  message?: string;
};

export const loginErrorNewAccount: CommonResultMessage = {
  result: false,
  message: `認証失敗:ログインに失敗しました。メールアドレスが登録済でないかご確認ください。`,
};

export const loginErrorExistAccount: CommonResultMessage = {
  result: false,
  message: `認証失敗:ログインに失敗しました。 メールアドレスとパスワードが間違っていないかご確認ください。`,
};
