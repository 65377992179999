import { Backdrop, Button, Container, Typography } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { disableCookie } from "../api/logoutApi";
import { MovePreviousPageButton } from "./login/MovePreviousPage";

export const Logout = () => {
  const { callback } = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const logout = async () => {
    setIsButtonDisabled(true);
    //cookieの削除
    await disableCookie();

    //firebase authからのログアウト
    const auth = getAuth();
    await signOut(auth);

    setMessage("ログアウトしました。");

    setIsButtonDisabled(false);
  };
  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Typography variant="h5">ログアウト</Typography>
      <Button onClick={logout} variant="contained" fullWidth>
        ログアウト
      </Button>
      <Typography variant="body1">{message}</Typography>
      <MovePreviousPageButton callback={callback} />
    </Container>
  );
};
