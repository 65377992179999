import { Backdrop, Button } from "@mui/material";
import { Auth, updateCurrentUser, User, UserInfo } from "firebase/auth";
import { useState } from "react";
import { unlinkAccount } from "../../../api/loginApi";
import { loginErrorHandler } from "../util/loginErrorHandler";
import { ProviderId } from "../type";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";

type ProviderUnlinkProp = {
  providerId: ProviderId;
  auth: Auth;
  setMessage: (prop: string) => void;
  setProviderData: (prop: UserInfo[]) => void;
};

export const ProviderUnlinkButton: React.FC<ProviderUnlinkProp> = (prop) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { providerId, auth, setMessage } = prop;
  const handleUnlinkButton = async () => {
    if (auth.currentUser) {
      setIsButtonDisabled(true);
      await unlinkAccount(auth.currentUser, providerId).catch((e) =>
        loginErrorHandler(e, setMessage)
      );
      console.log(auth.currentUser.providerData);
      setIsButtonDisabled(false);
      prop.setProviderData([...auth.currentUser.providerData]);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => {
          handleUnlinkButton();
        }}
        startIcon={
          providerId === "twitter.com" ? (
            <TwitterIcon />
          ) : providerId === "google.com" ? (
            <GoogleIcon />
          ) : null
        }
      >
        {prop.children}
      </Button>
    </>
  );
};
