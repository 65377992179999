import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import "../css/App.css";
import honeyLogo from "../static/honey.png";
import igLogo from "../static/ig_400x400.jpg";
import puzzlePediaLogo from "../static/puzzle-pedia.png";
import ruleElementLogo from "../static/rule_element.jpg";
import puzsqLogo from "../static/puzsq.jpg";

function TopPage() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LogicPuzzle.App
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ maxWidth: "md", my: 5 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <Card sx={{ maxWidth: 345, py: 2, my: 2 }}>
              <CardMedia
                component="img"
                height="320"
                image={puzsqLogo}
                alt="puzsq logo"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Puzzle Square
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  is one of the largest logicpuzzle posting site in Japan
                </Typography>
              </CardContent>
              {
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      window.open("https://puzsq.logicpuzzle.app/");
                    }}
                  >
                    Go
                  </Button>
                </CardActions>
              }
            </Card>
          </Grid>
          <Grid xs={12} md={4}>
            <Card sx={{ maxWidth: 345, py: 2, my: 2 }}>
              <CardMedia
                component="img"
                height="320"
                image={igLogo}
                alt="instructionless grid"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Instructionless Grid
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  is a puzzle site by five creators that presents 101
                  instructionless puzzles.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    window.open("https://ig.logicpuzzle.app/");
                  }}
                >
                  Go
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={12} md={4}>
            <Card sx={{ maxWidth: 345, py: 2, my: 2 }}>
              <CardMedia
                component="img"
                height="320"
                image={puzzlePediaLogo}
                alt="puzzle pedia"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Puzzle Pedia
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  is a collection of information about logic puzzles in
                  Japanese.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    window.open("https://scrapbox.io/puzzle-pedia/");
                  }}
                >
                  Go
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={12} md={4}>
            <Card sx={{ maxWidth: 345, py: 2, my: 2 }}>
              <CardMedia
                component="img"
                height="320"
                image={ruleElementLogo}
                alt="rule element"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Rule Element
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  is a project to break down and arrange the rules of a logic
                  puzzle into elements.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    window.open(
                      "https://scrapbox.io/puzzle-pedia/%E3%83%AB%E3%83%BC%E3%83%AB%E8%A6%81%E7%B4%A0"
                    );
                  }}
                >
                  Go
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={12} md={4}>
            <Card sx={{ maxWidth: 345, py: 2, my: 2 }}>
              <CardMedia
                component="img"
                height="320"
                image={honeyLogo}
                alt="honey island"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Honey View
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  is visual tool for honey islands puzzle.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    window.open("https://honeyis.land");
                  }}
                >
                  Go
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Divider />
      <p>Powered by wand125</p>
    </>
  );
}

export default TopPage;
