import { Avatar, Box, Container, Typography } from "@mui/material";
import { Auth } from "firebase/auth";
import { ProviderLoginButton } from "./provider/ProviderLogin";
import { ProviderUnlinkButton } from "./provider/ProviderUnlink";
import { ProviderId } from "./type";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

const providerIdArray: Array<ProviderId> = ["twitter.com", "google.com"];

type ProviderProp = {
  auth: Auth;
  signup?: boolean;
};

export const ProviderArea = (prop: ProviderProp) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginY: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PeopleOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {prop.signup
            ? "他サービスのアカウントで新規登録"
            : "他サービスのアカウントでログイン"}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} maxWidth="xs">
          {providerIdArray.map((v) => {
            return (
              <ProviderLoginButton providerId={v} auth={prop.auth} key={v}>
                {v.slice(0, -4)}で{prop.signup ? "新規登録" : "ログイン"}
              </ProviderLoginButton>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
};
