import { Backdrop, Box, Button, TextField, Typography } from "@mui/material";
import { Auth, signInWithEmailAndPassword, updateEmail } from "firebase/auth";
import { useState } from "react";
import { validateMail } from "../../../lib/auth-api/validation";
import { loginErrorHandler } from "../util/loginErrorHandler";

export const ChangeMailaddress = ({
  auth,
  setMessage,
  setEmail,
}: {
  auth: Auth;
  setMessage: (prop: string) => void;
  setEmail: (prop: string | null) => void;
}) => {
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [newMailAddressInputValue, setNewMailAddressInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const clearMessage = () => {
    setMessage("");
  };
  const handleNewMailAddressInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const newName = e.target.value;
    setNewMailAddressInputValue(newName);
  };
  const handlePasswordInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setPasswordInputValue(newName);
  };

  const handleMailadressChangeButton = async () => {
    setIsButtonDisabled(true);
    const mail = auth.currentUser?.email ?? "";
    try {
      //まず現在のメールアドレスとパスワードでログイン
      await signInWithEmailAndPassword(auth, mail, passwordInputValue);
      if (!auth.currentUser) {
        setMessage("ますメールアドレスでログインしてください");
        setIsButtonDisabled(false);
        return;
      }

      const mailValidateResult = validateMail(newMailAddressInputValue);
      if (!mailValidateResult.result) {
        setMessage("無効なメールアドレスです");
        setIsButtonDisabled(false);
        return;
      }
      await updateEmail(auth.currentUser, newMailAddressInputValue);
      setMessage("変更に成功しました");
      setEmail(newMailAddressInputValue);
    } catch (e) {
      loginErrorHandler(e, setMessage);
    }
    setIsButtonDisabled(false);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <p>メールアドレス変更</p>

      <Box component={"form"}>
        <TextField
          margin="normal"
          fullWidth
          variant="outlined"
          label="password"
          type="password"
          value={passwordInputValue}
          onChange={handlePasswordInputValue}
        />
        <TextField
          margin="normal"
          fullWidth
          variant="outlined"
          label="new mail"
          value={newMailAddressInputValue}
          onChange={handleNewMailAddressInputValue}
          inputProps={{
            autoComplete: "email",
          }}
        />

        <Button
          variant="contained"
          fullWidth
          onClick={handleMailadressChangeButton}
          sx={{ mt: 3, mb: 2 }}
        >
          メールアドレス変更
        </Button>
      </Box>
    </>
  );
};
