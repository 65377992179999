import { Auth } from "@firebase/auth";
import {
  createUserWithEmailAndPassword
} from "firebase/auth";
import "firebase/functions";
import { commonAuthProcess } from "./commonAuthProcess";
import {
  loginErrorNewAccount
} from "./commonResultMessage";
require("firebase/auth");

export const signupWithMailSingleAccount = async (
  auth: Auth,
  mail: string,
  password: string
) => {
  const loginErrorResult = loginErrorNewAccount;
  const authProcess = () =>
    createUserWithEmailAndPassword(auth, mail, password);
  return commonAuthProcess(mail, password, authProcess, loginErrorResult);
};
