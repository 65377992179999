import { Backdrop, Container, Typography } from "@mui/material";
import { Auth } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { callbackArray } from "./login/data/callbackArray";

export const Refresh = ({
  auth,
  cookieCreated,
}: {
  auth: Auth;
  cookieCreated: boolean;
}) => {
  const { callback } = useParams();
  const navigate = useNavigate();

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user === null || user === undefined) {
      navigate("/login/" + callback);
      return;
    }
    if (cookieCreated) {
      let nohit = true;
      callbackArray.forEach((val) => {
        if (val.callback === callback) {
          nohit = false;
          window.location.href = val.url;
        }
      });
      if (nohit) {
        navigate("/");
      }
    }
  }, [loading, user, cookieCreated]);

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      />
      <Typography variant="body1">
        アカウント情報を確認しています。このままお待ちください。
      </Typography>
    </Container>
  );
};
