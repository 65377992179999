import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { callbackArray } from "./data/callbackArray";
export const MovePreviousPageButton = ({
  callback,
}: {
  callback: string | undefined;
}) => {
  const navigate = useNavigate();
  const backToPreviousPage = () => {
    let nohit = true;
    callbackArray.forEach((val) => {
      if (val.callback === callback) {
        nohit = false;
        window.location.href = val.url;
      }
    });
    if (nohit) {
      navigate("/");
    }
  };
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      onClick={backToPreviousPage}
    >
      前の画面に戻る
    </Button>
  );
};
