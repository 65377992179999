import React, { useState } from "react";

import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Backdrop } from "@mui/material";
import Typography from "@mui/material/Typography";
import { sendPasswordResetMail } from "../../../api/passwordReset";
import { useNavigate } from "react-router-dom";

type PasswordResetMailProp = {
  callback: string | undefined;
};
export const PasswordResetMail = ({ callback }: PasswordResetMailProp) => {
  const [mailAddressInputValue, setMailAddressInputValue] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const navigate = useNavigate();
  const handleMailAddressInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value;
    setMailAddressInputValue(val);
  };

  const handleSubmitButton = () => {
    sendPasswordResetMail(mailAddressInputValue).finally(() => {
      setPasswordResetSent(true);
    });
  };

  return (
    <Box sx={{ px: 2, py: 2 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Typography component="h1" variant="h5">
        パスワードリセット
      </Typography>
      {passwordResetSent ? (
        <Box>
          <Typography component="p" variant="body1">
            パスワード再設定メールを送信しました。
          </Typography>

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setPasswordResetSent(false);
            }}
          >
            メールアドレスを変えて再送
          </Button>

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              if (callback) {
                navigate(`/login/${callback}`);
              }
            }}
          >
            ログインページへ
          </Button>
        </Box>
      ) : (
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={mailAddressInputValue}
            onChange={handleMailAddressInputValue}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              handleSubmitButton();
            }}
          >
            送信
          </Button>
        </Box>
      )}
    </Box>
  );
};
