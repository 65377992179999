import { UserCredential } from "firebase/auth";
import { CommonResultMessage } from "./commonResultMessage";
import { userCredentialToCookie } from "./userCredentialToCookie";
import { validatePasswordAndMail } from "./validation";

export const commonAuthProcess = async (
  mail: string,
  password: string,
  authProcess: () => Promise<UserCredential>,
  loginErrorResult: CommonResultMessage
) => {
  const passwordAndMailCheckResult: CommonResultMessage =
    validatePasswordAndMail(password, mail);
  if (!passwordAndMailCheckResult.result) {
    return loginErrorResult;
  }
  try {
    const userCredential = await authProcess();
    return await userCredentialToCookie(userCredential);
  } catch (e) {
    return loginErrorResult;
  }
};
