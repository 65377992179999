import { Auth } from "@firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import "firebase/functions";
import { commonAuthProcess } from "./commonAuthProcess";
import { loginErrorExistAccount } from "./commonResultMessage";
require("firebase/auth");

export const loginWithMailSingleAccount = async (
  auth: Auth,
  mail: string,
  password: string
) => {
  const loginErrorResult = loginErrorExistAccount;
  const authProcess = () =>
  signInWithEmailAndPassword(auth, mail, password);
  return commonAuthProcess(mail, password, authProcess, loginErrorResult);
};
