import { Backdrop, Button } from "@mui/material";
import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { useState } from "react";
import { loginWithRedirect } from "../../../api/loginApi";
import { ProviderId } from "../type";

import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";

type ProviderLoginProp = {
  providerId: ProviderId;
  auth: Auth;
};

const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
//facebookは後で
const facebookProvider = new FacebookAuthProvider();

export const ProviderLoginButton: React.FC<ProviderLoginProp> = (prop) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { providerId, auth } = prop;
  const handleLoginButton = async () => {
    console.log(auth.currentUser);
    setIsButtonDisabled(true);
    switch (providerId) {
      case "twitter.com":
        await loginWithRedirect(auth, twitterProvider);
        break;
      case "google.com":
        await loginWithRedirect(auth, googleProvider);
    }
    setIsButtonDisabled(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => {
          handleLoginButton();
        }}
        startIcon={
          providerId === "twitter.com" ? (
            <TwitterIcon />
          ) : providerId === "google.com" ? (
            <GoogleIcon />
          ) : null
        }
      >
        {prop.children}
      </Button>
    </>
  );
};
