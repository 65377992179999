import { UserCredential } from "firebase/auth";
import { makeSessionCookie } from "./makeSessionCookie";

export const userCredentialToCookie = async (
    userCredential: UserCredential
  ) => {
    const token = await userCredential?.user?.getIdToken(true);
    console.log(token);
    if (token) {
      const result = await makeSessionCookie(token);
      if (result) {
        return { result: true, message: "認証成功" };
      } else {
        return {
          result: false,
          message:
            "認証失敗:セッションcookieが保存できませんでした。cookieを有効にしてください",
        };
      }
    } else {
      return {
        result: false,
        message: "認証失敗: tokenが発行できませんでした",
      };
    }
  };