import { User } from "@firebase/auth";
import { Card, CardContent, Container, Grid } from "@mui/material";

export const AccountInfo = ({ currentUser }: { currentUser: User | null }) => {
  return (
    <Grid container spacing={2}>
      <Container component="main" maxWidth="xs">
        <br />
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            ユーザーID: {currentUser?.uid ?? "未ログイン"}
            <br />
            メールアドレス: {currentUser?.email ?? "未設定"}
            <br />
            Google連携:{" "}
            {currentUser?.providerData.find(
              (e) => e.providerId === "google.com"
            )?.email ?? "未設定"}
            <br />
            Twitter連携:{" "}
            {currentUser?.providerData.find(
              (e) => e.providerId === "twitter.com"
            )?.displayName ?? "未設定"}
          </CardContent>
        </Card>
      </Container>
    </Grid>
  );
};
